// No Extra Padding on containers
.xf-megamenu .cmp-container,
.xf-megamenu--no-pad .cmp-container {
  padding: 0;
}

// Mega Menu Content Title
.xf-megamenu--title {
  padding: rem(28px 0);

  .cmp-linklist__header--text {
    @include ui-heading4;
  }
}

.xf-megamenu__menu {

  .cmp-linklist {
    padding: rem(0 32px);
  }

  // Link List Styles
  .cmp-linklist__header,
  .cmp-linklist__header a {
    @include ui-heading3;
    color: map-get($colors, nav-dark-text);
  }

  .cmp-linklist__item a {
    @include ui-heading2;
    color: map-get($colors, nav-dark-text);
  }

  .cmp-linklist__header--text:hover {
    text-decoration: none;
  }
}
