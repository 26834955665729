.cmp-nav-tab-btn {
  position: relative;
  @include ui-heading3;
  display: block;
  border: none;
  background-color: map-get($backgrounds, light-bg);
  text-transform: none;
  width: 100%;
  padding: rem(18px 8px 18px 48px);
  margin: 0;
  color: map-get($colors, nav-dark-text);
  text-align: left;
  cursor: pointer;

  @include media('>=desktop') {
    @include ui-display1;
    font-size: rem(16px);
    display: inline-block;
    width: auto;
    padding: rem(15px 8px);
    text-align: center;
  }

  @include media('<desktop') {
    &.jsa-is-mobile-inactive {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
    }

    &.jsa-is-mobile-active {
      padding-top: rem(36px);
      padding-bottom: rem(36px);
      padding-left: rem(32px);
    }
  }

  &:focus {
    outline: 1px solid map-get($colors, focus-outline);
    box-shadow: none;
  }

  &:hover {
    background-color: map-get($backgrounds, light-bg);
    border: none;
    color: map-get($colors, nav-dark-text);
  }
}

.cmp-nav-tab-btn__indicator {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;

  /* stylelint-disable declaration-no-important */
  // forcing the use of an !important because it needs to override AEM inline styles
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;

  /* stylelint-enable declaration-no-important */

  border-left-width: 20px;
  border-right-width: 20px;
  // this style color should be overwritten by JS (from CMS)
  border-bottom-width: 16px;
  bottom: -13px;
  // this style should be overwritten by JS
  left: 50%;
  transform: translate(-50%, 0);
}

@include media('<desktop') {
  .cmp-nav__tab-item:first-child > .cmp-nav-tab-btn {
    padding-top: rem(36px);
  }

  .cmp-nav__tab-item:last-child > .cmp-nav-tab-btn {
    padding-bottom: rem(36px);
  }
}

*:hover > .cmp-nav-tab-btn .cmp-nav-tab-btn__indicator {
  display: block;
}
