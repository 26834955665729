/* USED SPECIFICALLY FOR MOBILE */
.cmp-nav-btn-hdg {
  display: block;
  width: 100%;
  padding: rem(18px 25px 18px 48px);
  margin: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  text-align: left;
  transition: background-color map-get($transitions, default);

  &.jsa-is-mobile-inactive {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
  }

  &.jsa-is-mobile-active,
  &.jsa-is-mobile-active:hover {
    padding-top: rem(36px);
    padding-bottom: rem(36px);
    padding-left: rem(32px);
    background-color: map-get($backgrounds, light-bg);
  }
}
