.cmp-nav__sublinks {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 26%;

  & ul {
    list-style-type: none;
    padding: rem(20px 0);
    margin: 0;
  }
}

.cmp-nav__sublinks-item-link {
  display: block;
  padding: rem(16px 0);
  border: none;
  background: none transparent;
  @include ui-overline2;
  text-transform: uppercase;
  text-align: left;
  color: inherit;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
    border: none;
  }

  &:focus {
    outline: 1px solid map-get($colors, focus-outline);
    box-shadow: none;
  }
}

.cmp-nav__sublinks-item {
  width: 100%;
  padding: rem(0 64px);
}

.cmp-nav__megamenu-xf {
  position: absolute;
  top: 0;
  left: 100%;
  width: 285%; // calculating based on the width of the sidebar
  padding: rem(27px 30px);
  color: map-get($colors, nav-dark-text);
  visibility: hidden;
  pointer-events: none;
}

.cmp-nav__megamenu-xf.jsa-is-visible,
.cmp-nav__sublinks-item:hover > .cmp-nav__megamenu-xf,
.cmp-nav__tab-item:hover
  .cmp-nav__sublinks-item:first-child
  .cmp-nav__megamenu-xf,
.jsa-is-visible
  .cmp-nav__sublinks-item:first-child
  .cmp-nav__megamenu-xf {
  visibility: visible;
  pointer-events: all;
}

.cmp-nav__sublinks--font-light {
  color: map-get($colors, light-text);
}

.cmp-nav__sublinks--font-dark {
  color: map-get($colors, nav-dark-text);
}
