.cmp-nav--mobile-only {
  @include media('>=desktop') {
    display: none;
  }
}

.cmp-nav__header-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include media('>=desktop') {
    width: auto;
  }
}

.cmp-nav {
  position: relative;
  width: 100%;
  transition: all 0.5s ease-out;
  z-index: map-get($z, foreground);
  background-color: map-get($backgrounds, light-bg);

  & a {
    text-decoration: none;
  }
}

.jsa-sticky {
  // fills in space left by the transition to sticky so content doesn't shift
  padding-bottom: rem(78px);
}

.jsa-sticky .cmp-nav {
  position: fixed;
  top: 0;
  left: 0;
  animation-duration: 0.5s;
  animation-name: stickyjump;
  background-color: map-get($backgrounds, light-bg);
  // grid wrapper styling also being used in site.scss
  @include grid-wrapper();
}

.cmp-nav__hamburger-menu {
  cursor: pointer;
}

// .cmp-nav__megamenu-content == main container for sidebar and XFs

.cmp-nav__megamenu-content {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  padding-right: rem(64px);

  @include media('>=desktop') {
    display: inline-block;
    visibility: hidden;
    pointer-events: none;
    // dropdown nav centering because of wrapping styles
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.jsa-is-hidden {
    visibility: hidden;
    pointer-events: none;
  }

  &.jsa-is-visible {
    visibility: visible;
    pointer-events: all;
  }
}

.cmp-nav__tab-item:hover > .cmp-nav__megamenu-content {
  visibility: visible;
  pointer-events: all;
}

.cmp-nav__menu-icon {
  height: 14px;
  width: 19.5px;
}

// .cmp-nav__tabs == container for logo image && top-level nav
.cmp-nav__tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  @include media('>=desktop') {
    justify-content: space-between;
    flex-direction: row;
  }

  @include media('<desktop') {
    & .cmp-nav__tab-item > .cmp-button.cmp-button--nav,
    & .cmp-nav__tab-item > .cmp-button.cmp-button--nav:hover {
      background-color: map-get($backgrounds, light-bg);
    }
  }

  & .cmp-nav__logo {
    margin: rem(10px 0);
    max-width: rem(220px);
    max-height: rem(63px);

    @include media('>=desktop') {
      margin: 0;
    }

    & .cmp-button.cmp-button--nav {
      margin: 0;
      padding: 0;
    }
  }

  & ul {
    list-style-type: none;
    padding: 0;
  }

  & .cmp-nav__tab-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    position: absolute;
    top: 100%;
    background-color: map-get($backgrounds, light-bg);
    width: 0; // is set when a JS class gets added
    min-height: 100vh;

    @include media('>=desktop') {
      justify-content: space-evenly;
      flex-direction: row;
      position: static;
      top: auto;
      width: auto;
      min-height: auto;

      /* stylelint-disable declaration-no-important */
      // !important is needed to allow CMS background-color editing
      background-color: transparent !important;

      /* stylelint-enable declaration-no-important */
    }

    @include media('<desktop') {
      overflow: hidden;
      transform: translate(100%, 0);
      transition: transform 0.5s ease;

      &.jsa-is-mobile-open {
        width: 100vw;
        transform: translate(0, 0);
      }
    }
  }

  & .cmp-nav__tab-item--utility {
    padding-top: rem(40px);

    & .cmp-utility-nav__link.cmp-button {
      margin-bottom: rem(8px);
    }

    & .cmp-utility-nav__icon {
      height: rem(20px);
      margin-right: rem(12px);
    }
  }

  & .cmp-nav__tab-item--copyright {
    text-align: center;
    position: absolute;
    bottom: rem(60px);
    left: 0;
    right: 0;

    @include media('>=desktop') {
      @include accessibility-hidden;
    }
  }

  & .cmp-nav__tab-item--utility.jsa-is-mobile-inactive {
    @include accessibility-hidden;
  }

  & .cmp-nav__tab-item {
    width: 100%;
  }

  @include media('>=desktop') {
    & .cmp-nav__tab-item {
      padding: rem(12px 5px);
      width: auto;
    }

    & .cmp-nav__tab-item--utility {
      display: none;
    }
  }
}

.cmp-nav__logo img {
  max-width: 100%;
}

.cmp-nav__icon {
  height: rem(10px);
  width: rem(10px);
  stroke: map-get($colors, nav-dark-text);
}

@include media('>=desktop') {
  .cmp-nav__tab-item--icon-carat-down:after {
    display: inline-block;
    margin-left: rem(4px);
    content: '';
    transition: transform 0.3s ease-in-out;

    @include icon-carat-down;
  }

  .cmp-nav__tab-item--icon-carat-down:hover:after,
  .cmp-nav__tab-item--icon-carat-down.jsa-is-active:after {
    transform: rotateX(180deg);
  }
}

.cmp-nav__icon.carat-left {
  display: none;
}

.cmp-nav__icon.carat-right {
  @include media('>=desktop') {
    display: none;
  }
}

.jsa-is-mobile-active > .cmp-nav__icon {
  &.carat-left {
    display: inline-block;

    @include media('>=desktop') {
      display: none;
    }
  }

  &.carat-right {
    display: none;
  }
}

// mobile nav styles
.cmp-nav__mobile-item--text {
  @include ui-heading3;
}

// mobile nav sublinks (nested)
.cmp-nav__category-item .cmp-button--nav {
  display: block;
  transition: background-color map-get($transitions, default);

  &.jsa-is-mobile-active,
  &.jsa-is-mobile-active:hover {
    background-color: map-get($backgrounds, light-bg);
  }
}

.cmp-nav__category-list,
.cmp-nav__category-list .cmp-nav__subcategory-list,
.cmp-nav__category-list .cmp-nav__group-list {
  overflow: hidden;
  height: 0;

  @include media('<desktop') {
    transform: translate(100%, 0);
    transition: all 0.5s ease;
  }
}

.jsa-is-mobile-active + .cmp-nav__category-list,
.jsa-is-mobile-active + .cmp-nav__subcategory-list,
.jsa-is-mobile-active + .cmp-nav__group-list {
  height: auto;
  transform: translate(0, 0);
}

@include media('<desktop') {
  .cmp-nav__category-list.jsa-is-mobile-active,
  .cmp-nav__category-list ul.jsa-is-mobile-active {
    overflow: visible;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
}

@include media('>=desktop') {
  .cmp-nav__category-item {
    display: none;
  }
}

//
// CSS ANIMATIONS
// ------------------------------

@keyframes stickyjump {
  from {
    transform: translate(0, -100%);
  }

  to {
    transform: translate(0, 0);
  }
}
